import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, message, Table, Typography, Tag, Popover, Space, Select } from 'antd';
import { EditOutlined } from '@ant-design/icons'; // Import the Edit icon

import useDocumentPageHook from '../DocumentPage/DocumentPageHook';
import './LeadsPage.css';  // Assume we are using a CSS file for styles

const { Title, Text } = Typography;
const { Option } = Select;


const TasksPage = () => {
  const { sessions, setSessions, getSystemSessions, pollSystemTasks, resumeAnalysisTask, stopAnalysisTask } = useDocumentPageHook();
  const navigate = useNavigate();

  const fetchSessions = async () => {
    const data = await getSystemSessions();
    if (data) {
      setSessions(data);
    } else {
      message.error('Failed to load sessions. Try again later');
    }
  };

  useEffect(() => {
    fetchSessions();
  }, []);

  const getTagColor = (state) => {
    switch (state) {
      case 'RUNNING': // RUNNING
      case 'PAUSED': // PAUSED
        return 'yellow';
      case 'COMPLETED': // COMPLETED
        return 'green';
      case 'DEFAULT': // DEFAULT
      case 'FAILED': // FAILED
      case 'ABORTED': // ABORTED
        return 'red';
      default:
        return 'blue';
    }
  };

  const columns = [
    {
      title: 'Session Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Progress',
      dataIndex: 'url_details',
      key: 'url_count',
      render: (_, record) => (
        <Text>
          {record.analyzed_urls} / {record.total_urls_to_process} 
        </Text>
      )
    },  
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      render: (_, record) => (
        <Tag color={getTagColor(record.session_state)}>
          {record.session_state}
        </Tag>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'upload_date',
      key: 'upload_date',
      render: date => new Date(date).toLocaleString()
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => navigate(`/campaign/tasks/${record.session_id}`, { state: { session_id: record.session_id } })}>
            View Details
          </Button>
        </Space>
      )
    }
  ];

  return (
    <div className="my-leads-page">
      <div className="upload-section">
        <div className="upload-content">
          <img src="/upload_icon.png" alt="Upload icon" className="upload-icon" />
          <div className="upload-text">
            <h3>Initiate Lead Analysis</h3>
            <p>Go to setup page to initiate the task. The results will be shown here.</p>
          </div>
        </div>
      </div>

      <div className="gen-content-section">
        <Table
          columns={columns}
          dataSource={sessions}
          rowKey="session_id"
          pagination={{ pageSize: 50 }}
          />
      </div>
    </div>
  );
};

export default TasksPage;
