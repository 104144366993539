import React from 'react';
import { Form, Input, Select, Button, Card, Tooltip, InputNumber, DatePicker, Typography, Divider, Row, Col } from 'antd';
import { MinusCircleOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { decisionMakers, countries, industries, company_sizes } from '../HomePage/CompanyTargetOptions';

const { TextArea } = Input;
const { Title } = Typography;
const { Option } = Select;

// Step 1: Service Panel
const ServicePanelForm = ({ form, mode, visible }) => (
  <div style={{ display: visible ? 'block' : 'none' }}>
    <Form.Item
      name="name"
      label="Campaign name"
      rules={[{ required: true, message: "Name this campaign" }]}
    >
      <TextArea autoSize placeholder="" />
    </Form.Item>    

    <Title level={4}>
        Service my company provide:
    </Title>    
    <Form.List name="services" initialValue={[{}]}>
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, index) => (
            <Card key={field.key} style={{ marginBottom: 16 }}>
              <Form.Item
                {...field}
                name={[field.name, 'serviceName']}
                fieldKey={[field.fieldKey, 'serviceName']}
                label={`Service Name ${index + 1}`}
                rules={[{ required: true, message: "Please provide the service name" }]}
              >
                <Input placeholder="E.g CRM Consulting" />
              </Form.Item>
              <Form.Item
                {...field}
                name={[field.name, 'serviceDescription']}
                fieldKey={[field.fieldKey, 'serviceDescription']}
                label="Service Description"
                rules={[{ required: true, message: "Please provide the service description" }]}
              >
                <TextArea placeholder="Provide CRM consulting to SME companies" />
              </Form.Item>
              {fields.length > 1 && mode && (
                <MinusCircleOutlined onClick={() => remove(field.name)} />
              )}
            </Card>
          ))}
        </>
      )}
    </Form.List>
  </div>
);

// Step 2: Company Criteria
const CompanyCriteriaForm = ({ form, mode, visible }) => {
    const countries_options = countries.map(item => ({ value: item }));
    const industries_options = industries.map(item => ({ value: item }));
    const company_size_options = company_sizes.map(item => ({ value: item }));
    const filterOption = (input, option) =>
      (option?.value ?? '').toLowerCase().includes(input.toLowerCase()); 

      return (
        <div style={{ display: visible ? 'block' : 'none' }}>
        <Form.Item
          label="Target Company's Size"
          rules={[{ required: true, message: "Please provide your target company's size" }]}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: 8 }}>From</span>
            <Form.Item
              name="minSize"
              rules={[{ required: true, message: 'Please input the minimum size!' }]}
              style={{ margin: 0, marginRight: 16 }}
              validateStatus={form.getFieldError('minSize').length ? 'error' : ''}
              help=""
            >
              <InputNumber min={1} placeholder="Min" style={{ width: '100%' }} disabled={!mode} />
            </Form.Item>
            <span style={{ marginRight: 8 }}>to</span>
            <Form.Item
              name="maxSize"
              rules={[{ required: true, message: 'Please input the maximum size!' }]}
              style={{ margin: 0 }}
              validateStatus={form.getFieldError('maxSize').length ? 'error' : ''}
              help=""
            >
              <InputNumber min={1} placeholder="Max" style={{ width: '100%' }} disabled={!mode} />
            </Form.Item>
          </div>
          <Form.ErrorList errors={form.getFieldError('minSize').concat(form.getFieldError('maxSize'))} />
        </Form.Item>
        <Form.Item
          name="location"
          label="Location"
          rules={[{ required: true, message: "Please provide the location of your target company" }]}
        >
          <Select
            placeholder="Select location"
            mode="multiple"
            allowClear
            optionFilterProp="children"
            filterOption={filterOption}
            options={countries_options}
            disabled={!mode}
          />
        </Form.Item>
        <Form.Item
          name="industry"
          label="Industry"
          rules={[{ required: true, message: "Please provide the industry of your target company" }]}
        >
          <Select
            placeholder="Select industry"
            mode="multiple"
            allowClear
            filterOption={filterOption}
            options={industries_options}
            disabled={!mode}
          />
        </Form.Item>
        <Form.Item
          name="contactPreference"
          label="Contact Preference"
          rules={[{ required: true, message: "Please provide who you wish to connect" }]}
        >
          <Select
            mode="tags"
            placeholder="Select contact preference"
            options={decisionMakers}
            disabled={!mode}
          />
        </Form.Item>
        <Form.Item
          name="keywords"
          label="Keywords"
        >
          <TextArea autoSize placeholder="" disabled={!mode} />
        </Form.Item>
        <Form.Item
          label="Founded Year"
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: 8 }}>From</span>
            <Form.Item
              name="foundedYear"
              style={{ margin: 0, marginRight: 16 }}
              validateStatus={form.getFieldError('foundedYear').length ? 'error' : ''}
              help=""
            >
              <Select placeholder="Select Year" style={{ width: '100%' }} allowClear disabled={!mode}>
                {Array.from({ length: 2024 - 2014 + 1 }, (_, i) => (
                  <Option key={2014 + i} value={2014 + i}>
                    {2014 + i}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </Form.Item>
        <Form.Item
          name="needFunding"
          label="Does the company need to be funded?"
          rules={[{ required: true, message: "Please select if the company needs to be funded" }]}
        >
          <Select placeholder="Select an option" disabled={!mode}>
            <Select.Option value="yes">Yes</Select.Option>
            <Select.Option value="no">No</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          shouldUpdate={(prevValues, currentValues) => prevValues.needFunding !== currentValues.needFunding}
          noStyle
        >
          {({ getFieldValue }) =>
            getFieldValue('needFunding') === 'yes' ? (
              <>
                <Form.Item
                  name="lastFundingDate"
                  label={
                    <>
                      Last Funding Date Greater than 
                      <Tooltip title="If set, we will filter companies that have been funded later than the date set.">
                        <QuestionCircleOutlined style={{ marginLeft: 8 }} />
                      </Tooltip>
                    </>
                  }
                >
                  <DatePicker disabled={!mode} />
                </Form.Item>
              </>
            ) : null
          }
        </Form.Item>
    </div>
  );        
}

// Step 3: Company Insights
const CompanyInsightsForm = ({ form, mode, visible }) => (
  <div style={{ display: visible ? 'block' : 'none' }}>
    {/* The company is PREFERRED if */}

    <Form.List name={['keyQuestions', 'preferred']}>
      {(fields, { add, remove }) => (
        <>
            <Row justify="space-between" align="middle">
            <Col>
                <Title level={4}>
                The company is PREFERRED if:
                </Title>
            </Col>
            <Col>
                {fields.length < 5 && (
                <Button 
                    type="primary" 
                    onClick={() => add()} 
                    icon={<PlusOutlined />}
                    style={{marginTop:15}}
                    disabled={!mode || fields.length >= 5}>
                    Add a statement
                </Button>
                )}
            </Col>
            </Row>         
          {fields.map((field, index) => (
            <Card key={field.key} style={{ marginBottom: 16 }}>
              <Form.Item
                {...field}
                name={[field.name]}
                fieldKey={[field.fieldKey]}
                label={`Prefer Statement ${index + 1}`}
                rules={[{ required: true, message: "Please provide a statement" }]}
              >
                <Input placeholder={`E.g They have more than ${index + 1} engineers`} disabled={!mode} />
              </Form.Item>
              {fields.length > 1 && mode && (
                <MinusCircleOutlined onClick={() => remove(field.name)} />
              )}
            </Card>
          ))}
        </>
      )}
    </Form.List>

    <Divider />

    {/* The company is FILTERED if */}

    <Form.List name={['keyQuestions', 'filtered']}>
      {(fields, { add, remove }) => (
        <>
            <Row justify="space-between" align="middle">
            <Col>
                <Title level={4}>
                The company is FILTERED if:
                </Title>
            </Col>
            <Col>
                {fields.length < 5 && (
                <Button 
                    type="primary" 
                    onClick={() => add()} 
                    icon={<PlusOutlined />}
                    style={{marginTop:15}}
                    disabled={!mode || fields.length >= 5}>
                    Add a statement
                </Button>
                )}
            </Col>
            </Row>    
          {fields.map((field, index) => (
            <Card key={field.key} style={{ marginBottom: 16 }}>
              <Form.Item
                {...field}
                name={[field.name]}
                fieldKey={[field.fieldKey]}
                label={`Filter Statement ${index + 1}`}
              >
                <Input placeholder={`E.g They are using salesforce`} disabled={!mode} />
              </Form.Item>
              {fields.length > 1 && mode && (
                <MinusCircleOutlined onClick={() => remove(field.name)} />
              )}
            </Card>
          ))}
        </>
      )}
    </Form.List>

    <Divider />

    {/* More information I'd like to learn about company */}

    <Form.List name={['keyQuestions', 'moreInfo']}>
      {(fields, { add, remove }) => (
        <>
            <Row justify="space-between" align="middle">
            <Col>
                <Title level={4}>
                More information I'd like to learn about the company:
                </Title>
            </Col>
            <Col>
                {fields.length < 5 && (
                <Button 
                    type="primary" 
                    onClick={() => add()} 
                    icon={<PlusOutlined />}
                    style={{marginTop:15}}
                    disabled={!mode || fields.length >= 5}>
                    Add a statement
                </Button>
                )}
            </Col>
            </Row>         
          {fields.map((field, index) => (
            <Card key={field.key} style={{ marginBottom: 16 }}>
              <Form.Item
                {...field}
                name={[field.name]}
                fieldKey={[field.fieldKey]}
                label={`More Info Statement ${index + 1}`}
              >
                <Input placeholder={`E.g What are the company's growth plans?`} disabled={!mode} />
              </Form.Item>
              {fields.length > 1 && mode && (
                <MinusCircleOutlined onClick={() => remove(field.name)} />
              )}
            </Card>
          ))}
        </>
      )}
    </Form.List>
  </div>
);


const ContactInsightsForm = ({ form, mode, visible }) => (
    <div style={{ display: visible ? 'block' : 'none' }}>
      {/* The contact is PREFERRED if */}  
      <Form.List name={['contactKeyQuestions', 'preferred']}>
        {(fields, { add, remove }) => (
          <>
            <Row justify="space-between" align="middle">
            <Col>
                <Title level={4}>
                The contact is PREFERRED if:
                </Title>
            </Col>
            <Col>
                {fields.length < 5 && (
                <Button 
                    type="primary" 
                    onClick={() => add()} 
                    icon={<PlusOutlined />}
                    style={{marginTop:15}}
                    disabled={!mode || fields.length >= 5}>
                    Add a statement
                </Button>
                )}
            </Col>
            </Row>           
            {fields.map((field, index) => (
              <Card key={field.key} style={{ marginBottom: 16 }}>
                <Form.Item
                  {...field}
                  name={[field.name]}
                  fieldKey={[field.fieldKey]}
                  label={`Prefer Statement ${index + 1}`}
                  rules={[{ required: true, message: "Please provide a statement" }]}
                >
                  <Input placeholder={`e.g They are decision makers`} disabled={!mode} />
                </Form.Item>
                {fields.length > 1 && mode && (
                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                )}
              </Card>
            ))}
          </>
        )}
      </Form.List>
  
      <Divider />
  
  
      <Form.List name={['contactKeyQuestions', 'filtered']}>
        {(fields, { add, remove }) => (
          <>
            <Row justify="space-between" align="middle">
            <Col>
                <Title level={4}>
                The contact is FILTERED if:
                </Title>
            </Col>
            <Col>
                {fields.length < 5 && (
                <Button 
                    type="primary" 
                    onClick={() => add()} 
                    icon={<PlusOutlined />}
                    style={{marginTop:15}}
                    disabled={!mode || fields.length >= 5}>
                    Add a statement
                </Button>
                )}
            </Col>
            </Row>           
            {fields.map((field, index) => (
              <Card key={field.key} style={{ marginBottom: 16 }}>
                <Form.Item
                  {...field}
                  name={[field.name]}
                  fieldKey={[field.fieldKey]}
                  label={`Filter Statement ${index + 1}`}
                >
                  <Input placeholder={`e.g They have more than 10 years experience`} disabled={!mode} />
                </Form.Item>
                {fields.length > 1 && mode && (
                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                )}
              </Card>
            ))}
          </>
        )}
      </Form.List>
  
      <Divider />
  
      {/* More information I'd like to learn about contact */}
  
      <Form.List name={['contactKeyQuestions', 'moreInfo']}>
        {(fields, { add, remove }) => (
          <>
            <Row justify="space-between" align="middle">
            <Col>
                <Title level={4}>
                    More information I'd like to learn about the contact:
                </Title>
            </Col>
            <Col>
                {fields.length < 5 && (
                <Button 
                    type="primary" 
                    onClick={() => add()} 
                    icon={<PlusOutlined />}
                    style={{marginTop:15}}
                    disabled={!mode || fields.length >= 5}>
                    Add a statement
                </Button>
                )}
            </Col>
            </Row>           
            {fields.map((field, index) => (
              <Card key={field.key} style={{ marginBottom: 16 }}>
                <Form.Item
                  {...field}
                  name={[field.name]}
                  fieldKey={[field.fieldKey]}
                  label={`More Info Statement ${index + 1}`}
                >
                  <Input placeholder={`E.g What is this person's influence on buying decisions?`} disabled={!mode} />
                </Form.Item>
                {fields.length > 1 && mode && (
                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                )}
              </Card>
            ))}
          </>
        )}
      </Form.List>
    </div>
  );
    
  

// Step 5: Email Setup
const EmailSetupForm = ({ form, mode, visible }) => (
  <div style={{ display: visible ? 'block' : 'none' }}>
    <Form.Item
      name="emailTemplateSubject"
      label="Email Subject"
    >
      <Input placeholder="E.g Hey [name], Interested in learning how Dealight works?" disabled={!mode} />
    </Form.Item>
    <Form.Item
      name="emailTemplateName"
      label="Sender Name"
    >
      <Input placeholder="Name to show up in email" disabled={!mode} />
    </Form.Item>
    <Form.Item
      name="emailTemplateTitle"
      label="Sender Job Title"
    >
      <Input placeholder="Job Title to show up in email" disabled={!mode} />
    </Form.Item>
    <Form.Item
      name="emailTemplateValueProp"
      label="Value Proposition"
    >
      <TextArea placeholder="Value proposition in the outreach message" disabled={!mode} />
    </Form.Item>
    <Form.Item
      name="emailCallToAction"
      label="Call to Action"
    >
      <TextArea placeholder="Would you like to schedule a demo with us?" disabled={!mode} />
    </Form.Item>
  </div>
);

const LeadsSetupForm = ({ form, mode, currentStep }) => {
  return (
    <>
      <ServicePanelForm form={form} mode={mode} visible={currentStep === 0} />
      <CompanyCriteriaForm form={form} mode={mode} visible={currentStep === 1} />
      <CompanyInsightsForm form={form} mode={mode} visible={currentStep === 2} />
      <ContactInsightsForm form={form} mode={mode} visible={currentStep === 3} />
    </>
  );
};

export default LeadsSetupForm;
