import React, { useState, useEffect } from 'react';
import { Modal, Button, Card, Select, Statistic } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import useProfilePageHook from '../ProfilePage/ProfilePageHook';
import useDocumentPageHook from '../DocumentPage/DocumentPageHook';
import Notification from '../Notification/NotificationMessage';

const { Option } = Select;

const NewTaskModal = ({ onInitiateTask, maxURLCount, isProcessing, outOfCredit, modalVisible, setModalVisible }) => {
  const navigate = useNavigate();
  const { getCampaignOverview, getBusinessContext } = useDocumentPageHook();
  const [urlCount, setUrlCount] = useState(1);
  const [isInitiating, setIsInitiating] = useState(false);
  const [totalUrlsToAnalyze, setTotalUrlsToAnalyze] = useState('Loading...');
  const [campaignId, setCampaignId] = useState(null);

  useEffect(() => {
    if (modalVisible) {
      fetchBusinessContext();
    }
  }, [modalVisible]);  

  const fetchCampaignOverview = async (campaignId) => {
    setTotalUrlsToAnalyze('Loading...');
    const data = await getCampaignOverview(campaignId);
    if (data && data.total_urls_to_process !== undefined) {
      setTotalUrlsToAnalyze(data.total_urls_to_process);
    } else {
      Notification.error('Failed to load campaign overview. Try again later');
    }
  };

  const fetchBusinessContext = async () => {
    const contextData = await getBusinessContext();
    if (contextData && contextData.campaign && contextData.campaign.id) {
      setCampaignId(contextData.campaign.id);
      fetchCampaignOverview(contextData.campaign.id);
    } else {
      Notification.error('Failed to load business context. Try again later');
    }
  };

  const handleInitiate = async () => {
    setIsInitiating(true);
    await onInitiateTask(urlCount);
    setIsInitiating(false);
    setModalVisible(false); // Close the modal on successful initiation
  };

  const handleChange = (value) => {
    setUrlCount(value);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  return (
    <>
      <Modal
        title="Initiate Task"
        open={modalVisible}
        onCancel={handleModalClose}
        footer={[
          <Button key="cancel" onClick={handleModalClose}>
            Cancel
          </Button>,
          <Button
            key="run"
            type="primary"
            onClick={handleInitiate}
            disabled={isInitiating || totalUrlsToAnalyze === 0}
          >
            Run and analyze selected URLs ({urlCount})
          </Button>,
        ]}
      >
        <Card bordered={false} style={{ width: '100%' }}>
          <p style={{ marginTop: 0, marginBottom: 15 }}>
            DealightAI follows instructions in your sales agent setting.
          </p>
          <Statistic title="Potential Leads to Analyze:" value={totalUrlsToAnalyze} />
          <p>Select URLs to analyze until paused:</p>
          <Select defaultValue={1} style={{ width: '100%', marginBottom: 15 }} onChange={handleChange}>
            {[1, 10, 50, 100, 500, 1000].map((value) => (
              <Option key={value} value={value}>
                {value} URL{value > 1 ? 's' : ''}
              </Option>
            ))}
          </Select>
        </Card>
      </Modal>
    </>
  );
};

export default NewTaskModal;
