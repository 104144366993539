import React, { useState, useEffect } from 'react';
import { Form, Button, Spin, Typography, Row, Col, Steps, Modal, message, Tag } from 'antd';
import LeadsSetupForm from './LeadsSetupForm';
import NewTaskModal from '../LeadsPage/NewTaskModal'; // Import NewTaskModal for the modal
import useDocumentPageHook from '../DocumentPage/DocumentPageHook';
import useProfilePageHook from '../ProfilePage/ProfilePageHook';
import Notification from '../Notification/NotificationMessage';


import dayjs from 'dayjs';
import utc from 'dayjs-plugin-utc';
const { Title, Text } = Typography;
const { Step } = Steps;

const LeadsSetupPage = () => {
  const [form] = Form.useForm();
  const { createBusinessContext, getBusinessContext, initiateSystemTask, pollSystemTasks } = useDocumentPageHook();
  const { generationCredit, qualificationCredit, fetchUserProfile } = useProfilePageHook();
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showTaskModal, setShowTaskModal] = useState(false); // For showing the task modal
  const [taskSessionId, setTaskSessionId] = useState(null);

  useEffect(() => {
    fetchAndSetBusinessContext();
  }, [form]);

  const fetchAndSetBusinessContext = async () => {
    setIsLoading(true);
    try {
      const response = await getBusinessContext();
      await fetchUserProfile();
      if (response && response.campaign) {
        const campaign = response.campaign;

        form.setFieldsValue({
            name: campaign.name,
            minSize: campaign.company_criteria.companySize?.min,
            maxSize: campaign.company_criteria.companySize?.max,
            location: campaign.company_criteria.location,
            industry: campaign.company_criteria.industry,
            keywords: campaign.company_criteria?.keywords,
            foundedYear: campaign.company_criteria.founded_year,
            emailTemplateSubject: campaign.company_criteria?.email_info?.emailTemplateSubject,
            emailTemplateName: campaign.company_criteria?.email_info?.emailTemplateName,
            emailTemplateTitle: campaign.company_criteria?.email_info?.emailTemplateTitle,
            emailTemplateValueProp: campaign.company_criteria?.email_info?.emailTemplateValueProp,
            emailCallToAction: campaign.company_criteria.emailCallToAction,
            
            // Set keyQuestions with must, preferred, and moreInfo
            keyQuestions: transformQuestionsToForm(campaign.company_criteria.key_questions),
          
            // Set services
            services: campaign.company_criteria.services?.map(q => ({
              serviceName: q.serviceName,
              serviceDescription: q.serviceDescription
            })),
          
            // Need Funding
            needFunding: campaign.company_criteria.needFunding,
            lastFundingDate: campaign.company_criteria.lastFundingDate ? dayjs.utc(campaign.company_criteria.lastFundingDate) : null,
          
            // Contact Preferences
            contactPreference: campaign.contact_people_criteria.contact_preferences,
          
            // Set contactKeyQuestions with must, preferred, and moreInfo
            contactKeyQuestions: transformQuestionsToForm(campaign.contact_people_criteria.key_questions)
          });          

      }
    } catch (error) {
      console.error('Failed to fetch business context:', error);
      Notification.error('Failed to load existing information. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };  

  const getStepFields = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return ['services'];
      case 1:
        return ['name', 'location', 'industry', 'minSize', 'maxSize'];
      case 2:
        return ['keyQuestions'];
      case 3:
        return ['contactKeyQuestions'];
      case 4:
        return ['emailTemplateSubject', 'emailTemplateName', 'emailTemplateTitle', 'emailTemplateValueProp', 'emailCallToAction'];
      default:
        return [];
    }
  };

  const transformQuestionsToForm = (questions) => {
    const keyQuestions = {
      preferred: [],
      filtered: [],
      moreInfo: [],
    };
  
    try {
      questions?.forEach((q) => {
        switch (q.tag) {
          case 'PREFERRED':
            keyQuestions.preferred.push(q.question || null);
            break;
          case 'FILTERED':
            keyQuestions.filtered.push(q.question || null);
            break;
          case 'MORE_INFO':
            keyQuestions.moreInfo.push(q.question || null);
            break;
          default:
            keyQuestions.preferred.push(q.question || null);
            break;
        }
      });
  
      // Ensure there is at least one empty field for each section in case they are empty
      if (keyQuestions.preferred.length === 0) keyQuestions.preferred.push(null);
      if (keyQuestions.filtered.length === 0) keyQuestions.filtered.push(null);
      if (keyQuestions.moreInfo.length === 0) keyQuestions.moreInfo.push(null);
  
    } catch (error) {
      console.error('Error transforming questions:', error);
      // In case of error, return an empty keyQuestions object
      return {
        preferred: [null],
        filtered: [null],
        moreInfo: [null],
      };
    }
  
    return keyQuestions;
  };
  

  const transformKeyQuestions = (keyQuestions) => {
    let questionIdCounter = 1; // Initialize question ID counter
  
    const transformQuestions = (questionsArray, tag) => {
      return questionsArray
        .filter(question => question) // Remove null or empty questions
        .map(question => ({
          question,
          tag,
          question_id: questionIdCounter++, // Increment question_id for each question
        }));
    };
  
    // Merge all questions into a single array
    const transformedQuestions = [
      ...transformQuestions(keyQuestions.preferred || [], "PREFERRED"),
      ...transformQuestions(keyQuestions.filtered || [], "FILTERED"),
      ...transformQuestions(keyQuestions.moreInfo || [], "MORE_INFO"),
    ];
  
    return transformedQuestions;
  };  

  const handleNext = async () => {
    try {
      if (currentStep === 2) {
        // Custom validation for `keyQuestions` - make sure at least one is filled
        const keyQuestions = form.getFieldValue('keyQuestions')?.preferred || [];
        if (keyQuestions.length === 0 || !keyQuestions[0]) {
          throw new Error('At least one key question must be provided.');
        }
      }

      if (currentStep === 3) {
        // Custom validation for `contactKeyQuestions` - make sure at least one is filled
        const contactKeyQuestions = form.getFieldValue('contactKeyQuestions')?.preferred || [];
        if (contactKeyQuestions.length === 0 || !contactKeyQuestions[0]) {
          throw new Error('At least one contact key question must be provided.');
        }
      }

      // Validate only the fields for the current step
      await form.validateFields(getStepFields(currentStep));
      setCurrentStep(currentStep + 1);
    } catch (error) {
      message.error(error.message || 'Please fill in all required fields.');
    }
  };

  const handlePrev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSubmitForm = () => {
    form.validateFields().then(handleSaveAndInitiate).catch(handleValidationError);
  };  

  const handleSaveAndInitiate = async (values) => {
    const formData = new FormData();
    const name = values.name;
    const product_info = {
    };
    const email_info = {
      emailTemplateSubject: values.emailTemplateSubject,
      emailTemplateName: values.emailTemplateName,
      emailTemplateTitle: values.emailTemplateTitle,
      emailTemplateValueProp: values.emailTemplateValueProp,      
    }
    const company_criteria = {
      companySize: {
        min: values.minSize,
        max: values.maxSize
      },
      services: values.services,
      keywords: values.keywords,
      location: values.location,
      industry: values.industry,
      founded_year: values.foundedYear,
      key_questions: transformKeyQuestions(values.keyQuestions),
      needFunding: values.needFunding,
      email_info: email_info,
      emailCallToAction: values.emailCallToAction,
      lastFundingDate: values.lastFundingDate ? values.lastFundingDate.toISOString() : null
    };
    const contact_people_criteria = {
      contact_preferences: values.contactPreference,
      key_questions: transformKeyQuestions(values.contactKeyQuestions)
    };
    const campaign = { name, product_info, company_criteria, contact_people_criteria };

    formData.append('campaign', JSON.stringify(campaign));

    try {
      await createBusinessContext(formData);
      setShowTaskModal(true)
    } catch (error) {
      console.error('Failed to create business context:', error);
      Notification.error('Failed to save business context. Please try again.');
    }
  };

  const handleValidationError = (errorInfo) => {
    const errorMessage = errorInfo.errorFields.map(field => `${field.name[0]}: ${field.errors[0]}`).join(', ');
    message.error(errorMessage);
  };  

  const handleInitiateTask = async (urlCount) => {
    const session_id = await initiateSystemTask(urlCount);
    if (session_id) {
      pollSystemTasks(session_id);
    }
  };

  const steps = [
    'Service',
    'General',
    'Company',
    'Contact',
  ];

  return (
    <div className="leads-setup-page">
      {isLoading ? (
        <Spin size="large" />
      ) : (
        <div style={{
            width: '862px',
            margin: '0 auto',
            padding: '22px 66px',
            backgroundColor: '#fff',
            borderRadius: '16px',
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
          }}>
          <Row justify="space-between" align="middle" style={{ marginBottom: '20px' }}>
            <Col >
              <Title level={2}>Campaign Setup</Title>
            </Col>
            <Col >
              <Tag color="default" style={{ padding: '8px 16px' }}>
                <Text strong>Generation Credit: {generationCredit}</Text>
              </Tag>
                <Button onClick={handlePrev} disabled={currentStep === 0} style={{ marginRight: '8px', marginTop: '15px' }}>
                  Previous
                </Button>
                {currentStep < steps.length - 1 ? (
                  <Button type="primary" onClick={handleNext} style={{width:120}}>
                    Next
                  </Button>
                ) : (
                  <Button type="primary" onClick={handleSubmitForm}>
                    Save and Initiate
                  </Button>
                )}
            </Col>
          </Row>


          <Row justify="center">
            <Col>
              <Steps current={currentStep}>
                {steps.map((step, index) => (
                  <Step key={index} title={step} />
                ))}
              </Steps>
            </Col>
          </Row>


          <Form form={form} layout="vertical" style={{ marginTop: 32 }} onFinish={handleSubmitForm}>
            <LeadsSetupForm form={form} mode={true} currentStep={currentStep} />
          </Form>

          {/* Task Modal */}

          <NewTaskModal
            onInitiateTask={handleInitiateTask}
            maxURLCount={1000}
            isProcessing={false}
            outOfCredit={false}
            modalVisible={showTaskModal} // Modal visibility controlled by state
            setModalVisible={setShowTaskModal}
          />
        </div>
      )}
    </div>
  );
};

export default LeadsSetupPage;
