import React, { useState } from 'react';
import { Modal, Button, Upload, Card, notification, Table } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const ImportCSVModal = ({ onSubmit, visible, setVisible }) => {
  const [fileList, setFileList] = useState([]);

  const isValidDomain = (domain) => {
    const domainPattern = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return domainPattern.test(domain);
  };

  const handleUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const lines = e.target.result.split('\n');
      const headers = lines[0].split(',').map(h => h.trim().toLowerCase());

      const getFieldIndices = (fieldNames) => {
        return fieldNames.map(fieldName => headers.indexOf(fieldName));
      };

      const firstNameIndices = getFieldIndices(['first_name', 'first name']);
      const lastNameIndices = getFieldIndices(['last_name', 'last name']);
      const emailIndex = headers.indexOf('email');
      const titleIndex = headers.indexOf('title');
      const companyNameIndices = getFieldIndices(['company name', 'company_name']);
      const companyDomainIndices = getFieldIndices(['company_domain', 'company domain']);
      const linkedinUrlIndex = getFieldIndices(['linkedin', "linkedin url", "person linkedin url", "linkedin_url"]);

      const urls = [];
      const invalidEntries = [];

      const parseCSVLine = (line) => {
        const regex = /,(?=(?:(?:[^"]*"){2})*[^"]*$)/;
        return line.split(regex).map(field => field.replace(/(^"|"$)/g, '').trim());
      };

      for (let i = 1; i < lines.length; i++) {
        const fields = parseCSVLine(lines[i]);
        const firstName = firstNameIndices.some(index => index !== -1 && fields[index]) ? fields[firstNameIndices.find(index => index !== -1)] : '';
        const lastName = lastNameIndices.some(index => index !== -1 && fields[index]) ? fields[lastNameIndices.find(index => index !== -1)] : '';
        const email = emailIndex !== -1 ? fields[emailIndex] : '';
        const title = titleIndex !== -1 ? fields[titleIndex] : '';
        const companyName = companyNameIndices.some(index => index !== -1 && fields[index]) ? fields[companyNameIndices.find(index => index !== -1)] : '';
        let companyDomain = companyDomainIndices.some(index => index !== -1 && fields[index]) ? fields[companyDomainIndices.find(index => index !== -1)] : '';
        let linkedinUrl = linkedinUrlIndex.some(index => index !== -1 && fields[index]) ? fields[linkedinUrlIndex.find(index => index !== -1)] : '';

        companyDomain = companyDomain?.replace(/^(https?:\/\/)?(www\.)?/, '');
        if (linkedinUrl && !/^https?:\/\//i.test(linkedinUrl)) {
          linkedinUrl = `https://${linkedinUrl}`;
        }        

      
        const errors = [];
      
        if (!firstName) {
          errors.push('Missing First Name.');
        }
        if (!lastName) {
          errors.push('Missing last Name.');
        }        
        if (!email) {
          errors.push('Missing email.');
        }
        if (!companyDomain) {
          errors.push('Missing company domain.');
        } else if (!isValidDomain(companyDomain)) {
          errors.push('Invalid company domain.');
        }
        if (linkedinUrl) {
          try {
            const parsedLinkedInUrl = new URL(linkedinUrl);
            if (!isValidDomain(parsedLinkedInUrl.hostname)) {
              errors.push('Invalid LinkedIn URL domain.');
            }
          } catch {
            errors.push('Invalid LinkedIn URL format.');
          }
        }
      
        if (errors.length === 0) {
          urls.push({ firstName, lastName, email, title, companyName, companyDomain, linkedinUrl });
        } else {
          invalidEntries.push({ fields, errors });
      
          notification.error({
            message: `Invalid Entry Detected (Row ${i + 1})`,
            description: (
              <div>
                <p>The following issues were found:</p>
                <ul>
                  {errors.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </div>
            ),
          });
        }
      }
      

      onSubmit(urls, file.name);  // Pass file name along with the data
      setVisible(false);
      setFileList([]);
    };
    reader.readAsText(file);
    return false;
  };

  const handleChange = ({ fileList }) => setFileList(fileList);

  // Define the columns for the description table
  const columns = [
    {
      title: 'Field Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Required',
      dataIndex: 'explanation',
      key: 'explanation',
    },
    {
      title: 'Example',
      dataIndex: 'example',
      key: 'example',
    },
  ];

  // Define the data for the description table
  const data = [
    {
      key: '1',
      name: 'First Name',
      explanation: 'Required.',
      example: 'John',
    },
    {
      key: '2',
      name: 'Last Name',
      explanation: 'Required.',
      example: 'Doe',
    },
    {
      key: '3',
      name: 'Email',
      explanation: 'Required.',
      example: 'johndoe@example.com',
    },
    {
      key: '4',
      name: 'Company Domain',
      explanation: 'Required.',
      example: 'acme.com',
    },
    {
      key: '5',
      name: 'LinkedIn URL',
      explanation: 'Optional but recommended.',
      example: 'https://linkedin.com/in/johndoe',
    },
    {
      key: '6',
      name: 'Title',
      explanation: 'Optional.',
      example: 'Software Engineer',
    },
    {
      key: '7',
      name: 'Company Name',
      required: 'Optional',
      explanation: 'Optional.',
      example: 'Acme Corporation',
    },    
  ];

  return (
    <>
      <Modal
        title="Import CSV"
        visible={visible}
        onCancel={() => {
          setVisible(false);
          setFileList([]);
        }}
        footer={null}
        width={800}
      >
        <Card bordered={false}>
          <div style={{display:'flex'}}>
          <p style={{ marginTop: 5, marginRight:15 }}>Follow below mappings when uploading CSV data.</p>
          <Upload
            beforeUpload={handleUpload}
            accept=".csv"
            fileList={fileList}
            onChange={handleChange}
            style={{ width: '100%' }}
          >
            <Button type="primary" icon={<UploadOutlined />} style={{ width: '100%', marginTop: '0px' }}>
              Click to Upload CSV
            </Button>
          </Upload>
          </div>       
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            rowKey="key"
            style={{ marginBottom: '20px' }}
            scroll={{ x: 'max-content' }}  // Make the table scrollable if content exceeds
          />
        </Card>
      </Modal>
    </>
  );
};

export default ImportCSVModal;
